.landing-right{
    background: url('assets/images/landing-bg.png') ;
    background-size: cover;
    color: #fff;
    text-transform: uppercase;
    padding: 0px 100px;
    min-height: 100vh;
    align-items: center;
    display: flex;
}
.landing-right h1{
    font-size: 125px;
    font-weight: 200;
}